@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Medium.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-SemiBold.ttf');
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Bold.ttf');
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}

html,
body {
  height: 100%;
}

body {
  touch-action: pan-x pan-y pan-right pan-left;
  -webkit-tap-highlight-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled {
  -webkit-text-fill-color: #404040;
  opacity: 100;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.scroll-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6a6a6;
}

.active {
  color: #fe036a;
}

.active-mob {
  color: white;
  background-color: #fe036a;
}

.slick-list {
  margin: 0 -10px;
}

.slick-slide>div {
  padding: 0 10px;
}

@media only screen and (max-width: 768px) {
.slick-list {
    margin: 0 -5px;
  }

.slick-slide>div {
  padding: 0 5px;
}
}

input,
div[role='button'],
.dropdown {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Gmaps styles */
.gm-style iframe+div {
  border: none !important;
}

a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}